import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo";

const CatalogItemPage = () => (
	<Layout>
		<Seo title="No Lights Shine Out to Me" />

		<h2>No Lights Shine Out to Me</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST15 no lights front.jpg"
			alt="Jesse Rivest - No Lights Shine Out to Me - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on June 2, 2023.<br />
			(Actually released to streaming platforms on May 29 to allow time for uploading of lyrics.)
		</p>

		<h3>Words from Jesse</h3>
		<p>
			I'm pretty stoked about this recording; I really enjoyed making it.
			I built up the vocals and guitars first at home in my own studio to a programmed drum track;
			when Misael and I got to Orbis Studio to record the drums for real, I was elated with the studio's preparation and setup.
			Marcos at Orbis is a real pro! He had had a busy day previous,
			yet he still ensured that the Tama Starclassic was set up in the right place and all the mics were up and ready to go.
			Later when I was mixing I found the phase alignment and separation of the mics to be very pleasing—mixing was pretty easy!
			And I was excited about the selection of mics and preamps he was using; when I saw the Vintech preamp I immediately asked,
			"Is that going on the snare?" To which he replied, "Yes."
			Well, I don't want to geek out too much, but I even used an ancient RCA 44 in the mix that he put above Misael's head.
			Misael is a dream of a drummer. It only takes a few takes for him to find his groove and his signature in the song. We barely needed to comp anything together.
		</p>
		<p>
			Prior to going to the studio to record drums, I had sent the work-in-progress to Sasha (Alexander Raichenok) to see if he was keen.
			He came back with something like, "I think it needs some Wurlitzer". Sure enough, he then returned with some Wurlitzer from his Nord,
			and the track did indeed need that.
			It needed <span className="italic">him</span> playing it.
			Generously, Sasha also offered up a bass track from his Nord—it was fab!
			But I had already asked my friend Murilo to play bass on this song, and Murilo was keen.
			A couple weeks later, Murilo dropped by for the afternoon and recorded the electric bass guitar you're hearing.
			We went direct into my Phoenix Audio DRS-Q4 mk2, which is a lovely workhorse, and then I ran him through a cool chain which included:
			Fuse Audio Octafish, Plugin Alliance Ampeg SVTVR, Ignite Amps TPA-1 and NadIR, and the Ownhammer AMPG Bass IRs.
			Actually, Ownhammer IRs were used for several instruments on this track (but not all of them)!
			Well, enough geeking out.
		</p>
		<p>
			This song is a bit of fiction based on reminiscent images I have of twilight in the plaza in Buenos Aires.
			I think I painted a little loneliness for the narrator who is pining for an ex-partner.
			I know we all know what that's like, to some extent, so I'll be curious to see if this song resonates with you, the listener.
		</p>
		<p>
			I hope you enjoy <span className="italic">No Lights Shine Out to Me</span>; please share it if you do!
			And <Link to="/contact/">drop me a line</Link> if it resonates with you 😉.
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>
				No Lights Shine Out to Me (4:26)<br />
				T3180383835 - © Jesse Rivest 2023 (SOCAN/BMI, CMRRA)<br />
				CA-6P8-23-00002 - © Jesse Rivest 2023 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST15, © Jesse Rivest 2023, All Rights Reserved<br />
			UPC/EAN: 0198009207558<br />
			Released: June 2, 2023
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Drums recorded by Marcos Pagani at Orbis Studio in Brasilia, DF, Brazil.<br />
			Song written and performed by Jesse Rivest.<br />
			Drums performed by Misael Barros.<br />
			Wurlitzer performed by Alexander Raichenok.<br />
			Electric bass performed by Murilo Fabrino.<br />
			Electric guitar performed by Jesse Rivest.<br />
			Acoustic guitar performed by Jesse Rivest.<br />
			Backing vocals performed by Jesse Rivest.<br />
			Album cover photography by Jesse Rivest.<br />
			Album cover layout by Jesse Rivest.
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.<br />
			Thanks to Misael, Alexander, and Murilo for their performances!<br />
			Thanks to Gustavo Halfeld and Tico de Moraes for their ears and feedback.
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST15 no lights back.png"
			alt="Jesse Rivest - No Lights Shine Out to Me - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3 className="lyrics">Lyrics</h3>
		<div>
			<h4 className="lyrics__title">
				No Lights Shine Out to Me</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2023
			</h5>
			<p>
				Gone are the days<br />
				Things went our way<br />
				We knew they wouldn't stay<br />
				For long
			</p>
			<p>
				Oooo don't I understand<br />
				It's just what we had<br />
				That we shouldn't stay sad<br />
				For long
			</p>
			<p>
				Ahhh I still drift to you on the long nights<br />
				I take pleasure in calling your name<br />
				Then your voice it takes hold of me tight<br />
				And I struggle to free myself
			</p>
			<p>
				I sat in the park<br />
				Just before dark<br />
				Looked to that sky mark<br />
				You showed me
			</p>
			<p>
				Ohhh man I was stoned<br />
				Stuck there alone<br />
				Starlight chilled my bones<br />
				And froze me
			</p>
			<p>
				Ahhh I still dream of you on the long nights<br />
				I get dizzy from saying your name<br />
				Then your smile comes in bearing your light<br />
				And I don't wanna free myself
			</p>
			<p>
				I don't walk back home<br />
				'Cause the Nine takes me past your place<br />
				I'm beside myself out the window<br />
				The wind rushes me back to you<br />
				But no lights shine out to me<br />
				It's all in vain
			</p>
			<p>
				Ahhh I still drift to you on the long nights<br />
				I take pleasure in calling your name<br />
				Then your voice it takes hold of me tight<br />
				And I struggle to free myself
			</p>
			<p>
				I still dream of you on the long nights<br />
				I get dizzy from saying your name<br />
				Then your smile comes in bearing your light<br />
				And I don't wanna free myself
			</p>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
